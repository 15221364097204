import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Container from '../Container'
import config from '../../config'

const numberOfItems = 120

const MediaViewer = ({
  value, onChange, onClose, type,
}) => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [loading, setLoading] = useState(false)
  const imagesRef = useRef(null)

  useEffect(() => {
    imagesRef.current.scrollTop = 0
    setLoading(true)
    fetch(`${config.apiURL}media/list/${page}/${type}/${numberOfItems}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response
        .json()
        .then(responseData => ({ status: response.status, body: responseData })))
      .then(response => {
        if (response.status === 200) {
          setData(response.body)
          setHasMorePages(response.body.length === numberOfItems)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
        setLoading(false)
      })
  }, [page])

  const images = []
  for (let i = 0; i < numberOfItems; i++) {
    if (typeof data[i] !== 'undefined') {
      images.push(data[i])
    } else {
      images.push(false)
    }
  }

  const ImagePreview = ({ image, index }) => {
    return (
      <div
        onClick={image ? () => onChange(image.id) : null}
      >
        <div className="relative m-1 hover:opacity-75">
          <div className="relative">
            <img className="absolute top-0 left-0 right-0 bottom-0 ml-20" style={{ zIndex: -1, marginLeft: '40%', marginTop: '40%' }} src={`${process.env.REACT_APP_ENV === 'production' ? process.env.PUBLIC_URL : process.env.PUBLIC_URL_STAGING}/assets/images/no-image.jpg`}/>
            {!loading && image && <img
              className={`${image.id === value ? 'border-2 border-black' : ''} cursor-pointer w-full h-full object-cover`}
              src={`${config.apiURL}media/view/${image.id}`}
              alt="Preview"
            />}
          </div>
        </div>
      </div>
    )
  }
  const FilePreview = ({ image }) => (
    <div
      onClick={image ? () => onChange(image.id, image.originalName) : null}
      className="md:flex-basis-1/2 md:flex-basis-1/4 lg:flex-basis-1/6"
    >
      <div className="p-1">
        {image ? (
          <div
            className={`${
              image.id === value ? 'border-2 border-black' : ''
            } bg-grayLight block p-2 cursor-pointer`}
            title="Preview"
          >
            <span>
              {image.originalName}
                &nbsp;
              <a href={`${config.apiURL}media/download/${image.id}`}>↗</a>
            </span>
          </div>
        ) : (
          <div className="block p-2 bg-grayLight">&nbsp;</div>
        )}
      </div>
    </div>
  )

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col p-8 py-24 bg-white">
      <div className="absolute top-0 left-0 right-0 z-10" style={{ background: 'rgba(255,2555,255,.7)' }}>
        <Container>
          <div className="flex items-center justify-between">
            <h2 className="text-xl wght-semibold">
              {type === 'image' ? 'Images' : 'Files'}
              <span className="ml-2 text-xs">(page {page+1})</span>
            </h2>
            <span
              className="cursor-pointer hover:wght-semibold"
              onClick={onClose}
            >
              Close
            </span>
          </div>
        </Container>
      </div>
      <div ref={imagesRef} className="absolute top-0 bottom-0 left-0 right-0 overflow-scroll">
        <div className="flex flex-wrap my-12">
          {images.map((image, index) => {
            if (type === 'image') {
              return <ImagePreview image={image} key={index} index={index} />
            }
            return <FilePreview image={image} key={index} index={index} />
          })}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 z-10" style={{ background: 'rgba(255,2555,255,.7)' }}>
        <Container>
          <div className="flex justify-between">
            <span
              onClick={() => !loading && setPage(page - 1)}
              className={`mr-4 cursor-pointer hover:wght-semibold ${
                page > 0 ? '' : 'invisible pointer-events-none'
              }`}
            >
              Previous page
            </span>
            {hasMorePages && (
              <span
                onClick={() => !loading && setPage(page + 1)}
                className="mr-4 cursor-pointer hover:wght-semibold"
              >
                Next page
              </span>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default MediaViewer
