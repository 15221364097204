import React from 'react'

import Hidden from './Hidden'
import Text from './Text'
import Password from './Password'
import Textarea from './Textarea'
import Select from './Select'
import File from './File'
import Image from './Image'
import Number from './Number'
import Models from './Models'
import Checkbox from './Checkbox'
import Model from './Model'

const components = {
  hidden: {
    component: Hidden,
    width: '1/2',
  },
  model: {
    component: Model,
    width: 'full',
  },
  text: {
    component: Text,
    width: '1/2',
  },
  password: {
    component: Password,
    width: 'full',
  },
  number: {
    component: Number,
    width: '1/2',
  },
  textarea: {
    component: Textarea,
    width: 'full',
  },
  select: {
    component: Select,
    width: '1/2',
  },
  file: {
    component: File,
    width: '1/2',
  },
  image: {
    component: Image,
    width: '1/2',
  },
  checkbox: {
    component: Checkbox,
    width: '1/2',
  },
  models: {
    component: Models,
    width: 'full',
  },
}

const componentWidth = props => {
  if (props.name === 'position') {
    return 'w-full'
  }
  return components[props.type].width
}

const componentClassName = props => {
  let className = 'flex-1 w-full mb-4 md:pr-4 md:mb-8 flex-basis-full md:flex-grow-0'
  className += ` md:flex-basis-${componentWidth(props)} md:w-${componentWidth(props)}`
  if (props.type === 'hidden') {
    return 'hidden'
  }
  return className
}

const componentInnerClassName = props => {
  let className = ''
  if (props.name === 'position') {
    className += 'md:w-1/2 md:pr-2'
  }
  return className
}

const Wildcard = props => {
  const TagName = typeof components[props.type] !== 'undefined' ? components[props.type].component : null
  return TagName ? (
    <div className={componentClassName(props)}>
      <div className={componentInnerClassName(props)}>
        <TagName {...props} />
      </div>
    </div>
  ) : null
}

export default Wildcard