import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import config from '../config'
import Form from './Form/Form'
import flatten from './Lib/flatten'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Container from './Container'

const modelsWithoutList = ['Home', 'Common']

const Edit = () => {
  const history = useHistory()
  const { name: modelName, id: urlId } = useParams()
  const [id, setId] = useState(urlId)
  const [data, setData] = useState(false)
  const [name, setName] = useState(modelName)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [showTranslations, setShowTranslations] = useState(false)
  const [uploadingTranslate, setUploadingTranslate] = useState(false)
  const [downloadingTranslate, setDownloadingTranslate] = useState(false)
  const [saved, setSaved] = useState(false)

  const updateStored = (key, value, errs = []) => {
    if (typeof data.stored['modelName'] === 'undefined') data.stored['modelName'] = name
    data.stored[key] = value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  useEffect(() => {
    setData(false)
    setName(modelName)

    // TODO: refactor
    let title = modelName
    config.menu.forEach(item => {
      if (item.type === modelName && title === modelName) {
        title = item.title
      } else if (item.items) {
        item.items.forEach(item2 => {
          if (item2.type === modelName && title === modelName) {
            title = item2.title
          }
        })
      }
    })
    setTitle(`${id ? 'Edit' : 'Create'} ${title}`)

    fetch(`${config.apiURL}models/edit/${modelName}${id ? `/${id}` : ''}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (Array.isArray(response.body.stored)) {
          response.body.stored = {}
        }
        return response
      })
      .then(response => {
        if (response.status === 200) {
          setData(response.body)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          }
        }
      })
  }, [modelName, id])

  const save = (goToList = true, callback = false) => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      fetch(`${config.apiURL}models/edit/${modelName}`, {
        method: 'PUT',
        body: JSON.stringify(data.stored),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('token')}`,
        }
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setSaving(false)
          if (callback) callback(response)
          if (response.status === 200) {
            if (!goToList) {
              if (typeof id === 'undefined') {
                setId(response.body.id)
                history.push(`/admin/edit/${modelName}/${response.body.id}`)
              }
              setSaved(true)
              setTimeout(() => setSaved(false), 3000)
              return
            }
            if (modelsWithoutList.includes(modelName)) {
              history.push(`/admin/edit/${modelName}/1`)
            } else {
              history.push(`/admin/list/${modelName}`)
            }
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('token')
              history.push('/login')
            }
          }
        }).catch(() => setSaving(false))
    }
  }

  const Exports = () => {
    const download = () => {
      window.open(`${config.apiURL}models/translate/export/${modelName}/${id}/download`)
    }

    const upload = () => {
      const input = document.querySelector('input#import')
      if (input.files.length === 0) {
        alert('You must select a file')
      } else {
        if (window.confirm(`Are you sure you want to upload and replace the contents for the  **${input.files.length}** files? Make sure their contents are in the right format.`)) {
          setUploadingTranslate(true)
          const payload = new FormData();
          let i = 0
          for (let i = 0, f; f = input.files[i]; i++) {
            payload.append(`file${i}`, f);
          }
          fetch(`${config.apiURL}models/translate/import/${modelName}/${id}`,
            {
              body: payload,
              method: 'post',
              headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`,
              }
            })
            .then(response => response.json().then(data => ({ status: response.status, body: data })))
            .then(response => {
              setUploadingTranslate(false)
              if (response.status === 200) {
                alert(response.body.processed.join('\n'))
                window.location.reload()
              } else {
                alert('There was an error...')
              }
            })
        }
      }
    }

    return (
      <div className="mb-4">
        <div className="mb-4 cursor-pointer" onClick={() => setShowTranslations(!showTranslations)}>{showTranslations ? 'Hide translations' : 'Show translations'}</div>
        <div className={`${showTranslations ? 'block' : 'hidden'}`}>
          <div className="p-4 mb-4 bg-white rounded ">
            {downloadingTranslate ?
              <span className="text-xs cursor-pointer hover:wght-semibold">Exporting translation...</span>
              : <span onClick={download} className="text-xs cursor-pointer hover:wght-semibold">Export translations</span>}
          </div>
          <div className="p-4 mb-4 bg-white rounded ">
            <div>
              <input multiple className="bg-white appearance-none text-xxs" id="import" type="file" />
            </div>
            {uploadingTranslate ?
              <span className="text-xs cursor-pointer hover:wght-semibold">Uploading translation...</span>
              : <span onClick={upload} className="text-xs cursor-pointer hover:wght-semibold">Import translations</span>}
          </div>
          <span className="block text-xxs">You can upload multiples files. The format of the files MUST be <code>TypeID-LANGUAGE</code>. For example, you can import a file called <code>Literal1-fr_FR.csv</code> or <code>Printer33-en_AU.csv</code></span>
        </div>
      </div>
    )
  }

  return (
    <Container className="pt-10">
      <Helmet>
        <title>{title} – {config.title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="mb-8 text-xl wght-semibold">{title}</h2>
        <div>
          {!modelsWithoutList.includes(modelName) &&
            <Link to={`/admin/list/${modelName}`} className="cursor-pointer hover:wght-semibold">Go back</Link>
          }
        </div>
      </div>
      {data && <Exports />}
      {!data && <div className="mb-8">Loading...</div>}
      {
        data && modelName === name
        && <>
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
          />
          <div className="relative sticky bottom-0 flex justify-between px-4 py-2 mb-8 mr-4 bg-white">
            <div>
              {saving
                ? <span className="wght-bold">Saving...</span>
                : <span onClick={() => save(false)} className="cursor-pointer wght-normal hover:wght-semibold">Save &#8599; </span>
              }
              {saved && <span className="ml-4">Saved successfully!</span>}
            </div>
            <div>
              {saving
                ? <span className="wght-bold">Saving...</span>
                : <span onClick={() => save()} className="cursor-pointer wght-normal hover:wght-semibold">Save and close &#8599;</span>
              }
            </div>
          </div>
        </>
      }
    </Container >
  )
}

export default Edit