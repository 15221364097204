import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Number = ({
  storedData, showErrors, onChange, name, translatable, options: { label }, validations, languages,
}) => {
  let user = null
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
  }
  const allowedCountries = user && typeof user.allowedCountries !== 'undefined' ? user.allowedCountries : Object.keys(languages)

  const [language, setLanguage] = useState(translatable ? allowedCountries[0] : null)
  const [value, setValue] = useState(defaultValue(name, storedData, translatable))
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')

  const onChangeText = e => {
    if (translatable) {
      value[language] = e.target.value
      setValue({ ...value })
    } else {
      setValue(e.target.value)
    }
    setCurrentValue(e.target.value)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = <input
    name={name}
    className={`w-full rounded px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''} ${translatable && allowedCountries === language ? 'rounded-tl-none' : ''} bg-white outline-none placeholder-gray`}
    type="number"
    placeholder={label}
    value={currentValue}
    onChange={onChangeText}
  />

  return (
    <div>
      <label>{label}</label>
      {translatable
        ? <LanguageSelector language={language} languages={languages} onChangeLanguage={v => setLanguage(v)}>{input}</LanguageSelector>
        : input}
    </div>
  )
}

export default Number
