import React, {useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import TranslationTool from './TranslationTool'

const LanguageSelector = ({
  onChangeLanguage, language, children, languages, value, updateValue
}) => {
  let user = null
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
  }
  const allowedCountries = user && typeof user.allowedCountries !== 'undefined' ? user.allowedCountries : Object.keys(languages)

  const [currentValue, setCurrentValue] = useState({...value})
  const [showTranslations, setShowTranslations] = useState(false)
  const [openTranslationTool, setOpenTanslationTool] = useState(false)
  
  const updateCurrentValue = (key, v) => {
    currentValue[key] = `${v}`
    setCurrentValue({...currentValue})
  }

  const save = () => {
    updateValue({...currentValue})
    setShowTranslations(false)
  }

  useEffect(() => {
    if (openTranslationTool) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }, [openTranslationTool])

  return (
    <div>
      <div className="flex">
        <div className="flex overflow-scroll no-wrap">
          {Object.keys(languages).map(key => {
            if (allowedCountries.includes(key)) {
              return <div key={key} className={`text-xs rounded-t pt-1 px-2 cursor-pointer ${key === language ? 'bg-white wght-semibold' : ''}`} onClick={() => onChangeLanguage(key)}>{languages[key]}</div>
            }
            return null
          })}
        </div>
        {user.role === 'ROLE_ADMIN' && value && <div>
          <span onClick={() => setShowTranslations(true)} className="bg-white px-1 rounded cursor-pointer hover:bg-black hover:text-white">↗</span>
        </div>}
      </div>
      {children}
      <button onClick={() => setOpenTanslationTool(true)} className="bg-white px-2 py-1 mt-2 rounded">Translate</button>
      {showTranslations && <div className="z-50 fixed inset-0 flex items-center justify-center" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
        <div className="bg-grayLight rounded flex flex-col" style={{ height: '94vh', width: '50vw' }}>
          <div className="overflow-scroll flex-1 p-4">
            {Object.keys(languages).map(key => {
              if (allowedCountries.includes(key)) {
                return (
                  <div key={key} className="text-xs flex mb-2">
                    <span className="mr-2 w-12">{languages[key]}</span>
                    <input onChange={(e) => updateCurrentValue(key, e.target.value)} value={currentValue[key]} type="text" className="w-full rounded px-2 bg-white outline-none placeholder-gray"/>
                  </div>
                )
              }
              return null
            })}
          </div>
          <div className="flex p-4">
            <span onClick={save} className="bg-white px-2 cursor-pointer hover:bg-gray rounded">Save</span>
            <span onClick={() => setShowTranslations(false)} className="ml-2 px-2 cursor-pointer">Cancel</span>
          </div>
        </div>
      </div>}
      {/* TRANSLATION MODAL */}
      {openTranslationTool && (
        <TranslationTool
          closeCallback={() => setOpenTanslationTool(false)}
          updateCallback={(key, value) => updateCurrentValue(key, value)}
          saveCallback={save}
          language={language}
          languages={languages}
          currentValue={currentValue}
        />
      )}
    </div>
  )
}

export default LanguageSelector
