import React, {useState} from 'react'
import Cookies from 'js-cookie'

const url = 'https://hp-virtual-utils.dallonses.com/translator'

const TranslationTool = ({
  closeCallback,
  updateCallback,
  saveCallback,
  language,
  languages,
  currentValue,
  inputPlaceholder = 'Select language',
  textPlaceholder = 'The translated text will be displayed here.'
}) => {
  const initValue = {id: -1, text: textPlaceholder}
  const [languageToTranslate, setLanguageToTranslate] = useState(-1)
  const [translation, setTranslation] = useState(initValue)
  const [overwriteText, setOverwriteText] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [showAlert, setShowAlert] = useState(undefined)

  const options = [
    { id: -1, title: inputPlaceholder },
    ...Object.keys(languages).map((option) => ({ id: option, title: languages[option]}))
  ]

  const handleTranslation = async (text, input, output) => {
    const token = Cookies.get('token')
    const body = {
      text: text,
      inputLanguage: input,
      outputLanguages: [output],
    }
    try {
      setLoading(true)
      setError(false)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body)
      })
      const data = await response.json()
      if (data.error) {
        setError(data.error)
      } else {
        const { translations } = data
        setTranslation({ id: languageToTranslate, text: translations[0].text})
      }
      setLoading(false)
    } catch(err) {
      console.log(err)
      setError(false)
    }
  }

  const handleSave = () => {
    setLanguageToTranslate(translation.id)
    if (typeof currentValue[translation.id] === 'string' && currentValue[translation.id].length > 0) {
      // Text already exists for selected language
      if (overwriteText) {
        // 'Overwirte text' option checked. Should confirm operation.
        setShowAlert('confirm')
      } else {
        // 'Overwrite text' option not checked. Can't save translated text.
        setShowAlert('warning')
      }
    } else {
      // Selected language is empty. Can save translated text.
      saveAndClose()
    }
  }

  const handleCancel = () => {
    setTranslation(initValue)
    closeCallback()
  }

  const saveAndClose = () => {
    updateCallback(translation.id, translation.text)
    saveCallback()
    closeCallback()
  }

  return (
    <div className="z-50 fixed inset-0 flex items-center justify-center" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
      {showAlert ? (
        // ALERT MODAL
        <div
          className="bg-grayLight rounded flex flex-col p-8 gap-6 w-full md:w-2/3 lg:w-1/2 mx-4"
          style={{ height: 'max-content' }}
        >
          <p className='text-xs text-center'>
            {showAlert === 'confirm'
              ? <span>Are you sure you want to overwrite the existing text from language
              <span className='font-bold ml-1 uppercase'>{languages[translation.id]}</span>?</span>
              : <span>The selected language already has text. If you want to overwrite it you must select the option 
                  <span className='font-bold mx-1'>"Overwrite existing text from selected language"</span>
                 before saving.</span>
            }
          </p>
          <div className='flex items-center justify-center gap-4'>
            {showAlert === 'confirm' && <button
              onClick={() => saveAndClose()}
              className="bg-white px-2 cursor-pointer rounded hover:bg-gray"
            >
              Save
            </button>}
            <button
              onClick={() => setShowAlert(undefined)}
              className="ml-2 px-2 cursor-pointer"
            >
              {showAlert === "confirm" ? 'Cancel' : 'Go back'}
            </button>
          </div>
        </div>
      ) : (
        // TRANSLATION MODAL
        <div
          className="bg-grayLight rounded flex flex-col p-6 gap-2 w-full md:w-2/3 lg:w-1/2 mx-4"
          style={{ height: 'max-content' }}
        >
        <div className='flex w-full items-center gap-4'>
          <p>From:</p>
          <div className="uppercase px-2 rounded bg-white text-xs">{languages[language]}</div>
        </div>
          <p className='text-xs p-2 rounded border border-gray mb-4 overflow-y-scroll' style={{ height: 70 }}>{currentValue[language]}</p>
          <div className='flex w-full items-center gap-4'>
            <p>To:</p>
            <div className='flex-1 text-xs relative'>
            <div className="relative">
              <div className="absolute right-0 py-1 mr-2">&#8595;</div>
                <select
                  className={`w-full px-2 py-1 bg-white rounded outline-none appearance-none`}
                  onChange={(e) => setLanguageToTranslate(e.target.value)}
                  placeholder={inputPlaceholder}
                  value={languageToTranslate}
                >
                {options.map(option => (
                  <option
                    key={option.id}
                    value={option.id}
                    disabled={option.id === language}
                  >
                    {option.title}
                  </option>
                ))}
                </select>
              </div>
              <p className={`absolute text-error py-1 ${error ? 'opacity-100' : 'opacity-0'} transition-all duration-100 delay-200`}>{error}</p>
            </div>
            <button
              onClick={() => handleTranslation(currentValue[language], language, languageToTranslate)}
              className={`bg-white px-2 cursor-pointer rounded ${languageToTranslate === -1 || loading ? 'opacity-50 hover:bg-white' : 'hover:bg-gray'}`}
              disabled={languageToTranslate === -1 || loading}
            >
                Translate
              </button>
          </div>
          <p
            className={`text-xs p-2 rounded border border-gray mb-4 transition-all duration-200 ${error ? 'mt-6' : 'mt-0'} overflow-y-scroll ${loading ? 'flex items-center justify-center' : ''}`}
            style={{ height: 70 }}
          >
            {loading ? 'Translating text...' : translation.text}
          </p>
          <div className="flex items-center justify-between">
            <div>
              <label className='text-xs'>
                <input type="checkbox" className='mr-2' onChange={e => setOverwriteText(e.target.checked)} checked={overwriteText}/>
                Overwrite existing text from selected language
              </label>
            </div>
            <div>
              <button
                onClick={() => handleSave()}
                className={`bg-white px-2 cursor-pointer rounded ${languageToTranslate === -1 || loading || translation.text === textPlaceholder ? 'opacity-50 hover:bg-white' : 'hover:bg-gray'}`}
                disabled={languageToTranslate === -1 || loading || translation.text === textPlaceholder}
              >
                Save
              </button>
              <button
                onClick={() => handleCancel()}
                className={`ml-2 px-2 cursor-pointer ${loading ? 'opacity-50' : ''}`}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TranslationTool