import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import upload from '../Lib/upload'
import LanguageSelector from './LanguageSelector'
import MediaViewer from './MediaViewer'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const File = ({
  storedData, onChange, name, translatable, options: { label }, validations, languages,
}) => {
  let user = null
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
  }
  const allowedCountries = user && typeof user.allowedCountries !== 'undefined' ? user.allowedCountries : Object.keys(languages)

  const [language, setLanguage] = useState(translatable ? allowedCountries[0] : null)
  const [value, setValue] = useState(defaultValue(name, storedData, translatable))
  const [currentValue, setCurrentValue] = useState('')
  const [currentFileName, setCurrentFileName] = useState(value)
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [showMediaView, setShowMediaViewer] = useState(false)

  const updateValue = (v, fileName) => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    setCurrentFileName(fileName)
    if (showMediaView) setShowMediaViewer(false)
  }

  const onDrop = e => {
    e.stopPropagation()
    e.preventDefault()
    setUploading(true)
    upload(e.dataTransfer.files[0], (id, fileInfo) => {
      updateValue(id, fileInfo.originalName)
      setUploading(false)
    }, () => {
      setUploading(false)
    })
  }

  const remove = e => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Confirm?')) {
      updateValue('', '')
    }
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [value, language, translatable])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    onChange(name, value, Errors(value, validations, translatable, languages))
  }, [value])

  const input = <div>
    <label>{label}</label>
    <div
      className="relative flex items-stretch items-center bg-white rounded cursor-pointer"
      onClick={() => setShowMediaViewer(true)}
    >
      <div onClick={remove} className="absolute top-0 right-0 mt-1 mr-2 text-gray hover:text-black">✕</div>
      <div
        onDragEnter={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
        onDragOver={e => e.preventDefault() && e.stopPropagation()}
        onDrop={e => onDrop(e) && setDragging(false)}
        className={`${dragging ? 'border-gray' : ''} border border-white rounded flex-1 text-xs py-3 text-gray flex justify-center items-center`}>
        <span className="text-center pointer-events-none">{uploading ? 'Uploading...' : <span>{currentFileName || 'Drag to upload'}<span className="block text-xxs">{currentFileName && 'Drag to upload or '}Click to select</span></span>}</span>
      </div>
    </div>
    {showMediaView && <MediaViewer type="file" value={currentValue} onChange={updateValue} onClose={() => setShowMediaViewer(false)} />}
  </div>

  return translatable
    ? <LanguageSelector language={language} languages={languages} onChangeLanguage={v => setLanguage(v)}>{input}</LanguageSelector>
    : input
}

export default File
