import React from 'react'

import Wildcard from './Wildcard'

const Form = ({
  name, data, onChange, showErrors, mode,
}) => (
  <div>
    <div data-name="static" className="flex flex-wrap">
      {typeof data.models[name].static !== 'undefined'
        && data.models[name].static.map(item => (
          <Wildcard
            mode={mode}
            onChange={onChange}
            showErrors={showErrors}
            storedData={data.stored}
            languages={data.languages}
            key={item.name}
            {...item}
            models={['models', 'model'].includes(item.type) ? data.models : null}
          />
        ))}
    </div>
    <div data-name="json" className="flex flex-wrap">
      {typeof data.models[name].json !== 'undefined'
        && data.models[name].json.map(item => (
          <Wildcard
            mode={mode}
            onChange={onChange}
            showErrors={showErrors}
            storedData={data.stored}
            languages={data.languages}
            key={item.name}
            {...item}
            models={['models', 'model'].includes(item.type) ? data.models : null}
          />
        ))}
    </div>
  </div>
)

export default Form
