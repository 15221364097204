import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Password = ({
  mode, storedData, showErrors, onChange, name, options: { label }, validations, hideRepeat, onKeyPress,
}) => {
  const [value, setValue] = useState(defaultValue(name, storedData))
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')
  const [currentRepeatedValue, setCurrentRepeatedValue] = useState('')

  const onChangeText = e => {
    setValue(e.target.value)
    setCurrentValue(`${e.target.value}`)
  }

  const onChangeRepeatedText = e => {
    setCurrentRepeatedValue(e.target.value)
  }

  useEffect(() => {
    const errs = Errors(value, validations, false, [], { repeated: currentRepeatedValue, mode })
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, currentRepeatedValue])

  const input = <input
    name={name}
    className={`w-full rounded px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''} bg-white outline-none placeholder-gray`}
    type="password"
    placeholder={label}
    value={currentValue}
    onChange={onChangeText}
    onKeyPress={onKeyPress}
  />

  const inputRepeated = <input
    name={name}
    className={`w-full rounded px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''} bg-white outline-none placeholder-gray`}
    type="password"
    placeholder={label}
    value={currentRepeatedValue}
    onChange={onChangeRepeatedText}
  />

  return (
    <div className="md:flex">
      <div className="flex-1 mb-8 md:mb-0">
        <label>{label}</label>
        {input}
        {showErrors && errors.length > 0 ?
          errors[0] : ''
        }
      </div>
      {hideRepeat === true
      || <div className="flex-1 md:ml-4">
        <label>{label} Repeat</label>
        {inputRepeated}
      </div>}
    </div>
  )
}

export default Password
