import React, { useState, useEffect } from 'react'
import ReactMde, { commands } from 'react-mde'
import * as Showdown from 'showdown'
import Cookies from 'js-cookie'
import config from '../../config'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const listCommands = [
  {
    commands: [
      commands.boldCommand,
      commands.italicCommand,
      commands.linkCommand,
      commands.unorderedListCommand,
      commands.orderedListCommand,
    ],
  },
]

const Textarea = ({
  storedData, showErrors, onChange, name, translatable, options: { label, markdown }, validations, languages,
}) => {
  let user = null
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
  }
  const allowedCountries = user && typeof user.allowedCountries !== 'undefined' ? user.allowedCountries : Object.keys(languages)

  const [language, setLanguage] = useState(translatable ? allowedCountries[0] : null)
  const [value, setValue] = useState(defaultValue(name, storedData, translatable))
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')
  const [selectedTab, setSelectedTab] = useState('write')
  const [textDir, setTextDir] = useState('auto')

  const onChangeText = v => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    setCurrentValue(v)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
    setTextDir((language === 'mea_MEA') ? 'rtl' : 'auto')
  }, [language, translatable])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const textarea = markdown ? (
    <div className={`${showErrors && errors.length > 0 ? 'border border-error' : ''}`}>
      <ReactMde
        commands={listCommands}
        value={currentValue}
        onChange={onChangeText}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        childProps={{ textArea: { 'dir': textDir } }}
        generateMarkdownPreview={md => Promise.resolve(converter.makeHtml(md))
        }
      />
    </div>) : (
    <div>
      <textarea
        name={name}
        className={`w-full h-32 px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''} rounded ${translatable && allowedCountries === language ? 'rounded-tl-none' : ''} bg-white outline-none resize-y`}
        placeholder={label}
        value={currentValue}
        onChange={e => onChangeText(e.target.value)}
        dir={textDir}
      />
    </div>
  )

  return (
    <div>
      <label>{label}</label>
      {translatable
        ? <LanguageSelector
          value={value}
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
          updateValue={v => {
          setValue(v)
          setCurrentValue(v[language])
        }}>
          {textarea}
        </LanguageSelector>
        : textarea}
    </div>
  )
}

export default Textarea
