import React from 'react'

const EnvironmentTag = ({ config }) => (
  <p
    className={`py-1 text-xs mr-4 rounded-full px-2 ${config.environment === 'staging'
      ? 'bg-orange' : 'bg-gray'
      } inline-block`}
  >
    {config.environment === 'staging' ? 'Staging' : 'Production'}
  </p>
)

export default EnvironmentTag
