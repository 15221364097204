import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Checkbox = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
}) => {
  let user = null
  if (Cookies.get('user')) {
    user = JSON.parse(Cookies.get('user'))
  }
  const allowedCountries = user && typeof user.allowedCountries !== 'undefined'
    ? user.allowedCountries
    : Object.keys(languages)

  const [language, setLanguage] = useState(
    translatable ? allowedCountries[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(parseInt(value) === 1)

  const onChangeText = e => {
    const checkboxValue = e.target.checked
    if (translatable) {
      value[language] = checkboxValue ? 1 : 0
      setValue({ ...value })
      setCurrentValue(checkboxValue)
    } else {
      setValue(checkboxValue ? 1 : 0)
      setCurrentValue(checkboxValue)
    }
  }

  useEffect(() => {
    setCurrentValue(
      translatable ? parseInt(value[language]) === 1 : parseInt(value) === 1,
    )
  }, [language, translatable])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <div>
      <input
        name={name}
        className={`rounded ${
          translatable && allowedCountries === language ? 'rounded-tl-none' : ''
        } px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } bg-white outline-none placeholder-gray`}
        type="checkbox"
        checked={currentValue}
        onChange={onChangeText}
        value="1"
      />
    </div>
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
    </div>
  )
}

export default Checkbox
