import React, { useState, useEffect } from 'react'
import config from '../config'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Container from './Container'

const Images = () => {
  const [items, setItems] = useState([])
  const [itemsOk, setItemsOk] = useState([])
  const [itemsNok, setItemsNok] = useState([])
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)

  const processFile = (item) => {
    const startDate = new Date()
    setProcessing(true)
    fetch(`${config.mediaURL}process`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({
        appName: config.mediaName,
        fileName: item.name,
      })
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {

        if (response.status === 200) {

          const body = new FormData()
          body.append('id', item.id)
          body.append('info', JSON.stringify(response.body.data))

          fetch(`${config.apiURL}media/update`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${Cookies.get('token')}`,
            },
            body
          })
            .then(response => response.json().then(data => ({ status: response.status, body: data })))
            .then(response => {
              const endDate = new Date()
              const duration = endDate.getTime() - startDate.getTime()
              if (response.status === 200) {
                setItemsOk([...itemsOk, {
                  name: item.name,
                  duration,
                }])
              } else {
                setItemsNok([...itemsNok, {
                  name: item.name,
                  duration,
                }])
              }
              const updatedItems = items.filter(i => Number(i.id) !== Number(item.id))
              setItems(updatedItems)
            })
        } else {


          const body = new FormData()
          body.append('id', item.id)
          body.append('info', response.body.errorCode === 404 ? 'not-found' : 'error')

          fetch(`${config.apiURL}media/update`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${Cookies.get('token')}`,
            },
            body
          })
            .then(response => response.json().then(data => ({ status: response.status, body: data })))
            .then(response => {
              setItemsNok([...itemsNok, {
                name: item.name,
                duration: 0,
              }])
              const updatedItems = items.filter(i => Number(i.id) !== Number(item.id))
              setItems(updatedItems)
            })
        }
      })
  }

  useEffect(() => {
    if (processing) {
      if (items.length > 0) {
        processFile(items[0])
      } else {
        console.log('OK')
      }
    }
  }, [items])

  const fetchPending = () => {
    setLoading(true)
    fetch(`${config.apiURL}media/pending`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setItems(response.body.items)
        } else {
          console.log('Error', response.status, response.body.error)
          setProcessing(false)
        }
      })
  }

  useEffect(() => {
    fetchPending()
  }, [])

  const startProcessingFiles = () => {
    if (processing) return
    processFile(items[0])
  }

  const mainContent = (
    <>
      <p className="mb-4"><strong>{items.length}</strong> files pending to process.</p>
      {items.length > 0 && <span onClick={startProcessingFiles} className="cursor-pointer inline-block p-2 py-1 bg-orange text-xs rounded">{processing ? 'Processing...' : 'Process'}</span>}
      {itemsOk.length > 0 && <>
        <p className="mt-4">Processed <strong>{itemsOk.length}</strong> items</p>
        {itemsOk.map(i => <p key={i.name} className="text-xs">{i.name} – {parseFloat(i.duration / 1000).toFixed(2)}s</p>)}
      </>}
      {itemsNok.length > 0 && <>
        <p><strong>{itemsNok.length} items failed</strong></p>
        {itemsNok.map(i => <p key={i.name} className="text-xs">{i.name} – {parseFloat(i.duration / 1000).toFixed(2)}s</p>)}
      </>}
    </>
  )

  return (
    <Container className="pt-10">
      <Helmet>
        <title>Images</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">Images</h2>
      </div>
      {loading && <p>Loading...</p>}
      {!loading && mainContent}
    </Container>
  )
}

export default Images