import React, { useState, useEffect } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import LanguageSelector from './LanguageSelector'
import Form from './Form'
import defaultValue from '../Lib/defaultValue'
import colorFromString from '../Lib/colorFromString'

const generateToken = length => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
    '',
  )
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}

const ListItem = SortableElement(({ value: { value: item, sorting, models, languages, updateStored, deleteModel, showErrors, modelsAvailable, index } }) => (
  <div className="bg-white rounded">
    <div
      className={`${sorting ? 'cursor-move' : ''} relative mt-2 rounded`}
      style={{
        backgroundColor: colorFromString(item.modelName, 0.3),
      }}
    >
      <div
        className={`flex items-center justify-between rounded-t ${
          sorting ? 'px-1 py-1 text-xs' : 'p-4'
        }`}
        style={{
          backgroundColor: colorFromString(item.modelName, 0.3),
        }}
      >
        <span className="wght-semibold">
          {modelsAvailable[item.modelName]}
        </span>
        {!sorting && <span
          className="text-xs cursor-pointer hover:wght-semibold"
          onClick={() => deleteModel(index)}
        >
          Delete
        </span>}
      </div>
      <div
        className={`p-2 ${
          sorting ? 'md:py-1 md:px-1 text-xs h-40 overflow-scroll pointer-events-none' : 'md:py-4 md:px-2'
        }`}
      >
        <Form
          innerForm={true}
          parentIsSorting={sorting}
          key={item.key}
          onChange={(n, v) => updateStored(index, item.modelName, n, v)}
          showErrors={showErrors}
          name={item.modelName}
          data={{ models, stored: item, languages }}
        />
      </div>
    </div>
  </div>
  )
)

const List = SortableContainer(({ items, sorting, models, languages, updateStored, deleteModel, showErrors, modelsAvailable }) => (
  <div>
    {Object.keys(items).map((itemKey, index) => (
      <ListItem
        key={`${items[itemKey].key}-${index}`}
        index={index}
        value={{ value: items[itemKey], sorting, models, languages, updateStored, deleteModel, showErrors, modelsAvailable, index }}
      />
    ))}
  </div>
))

const Model = ({
  showErrors,
  storedData,
  onChange,
  models,
  name,
  translatable,
  options: { label, model },
  languages,
  innerForm,
  parentIsSorting,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )

  const dValue = defaultValue(name, storedData, translatable, {})
  if (typeof dValue.key === 'undefined') dValue.key = generateToken(30)

  const [value, setValue] = useState(dValue)

  useEffect(() => {
    onChange(name, value)
  }, [value])

  const updateStored = ( modelName, key, v) => {
    const updatedValue = {...value}

    if (typeof updatedValue === 'undefined') { updatedValue = { modelName } }
    updatedValue[key] = v

    if (JSON.stringify(updatedValue) !== JSON.stringify(value)) {
      setValue(updatedValue)
    }
  }

  const addModel = modelKey => {
    let position = (value.length + 1) * 10
    if (
      value.length > 0
      && typeof value[value.length - 1].position !== 'undefined'
      && value[value.length - 1].position.length > 0
    ) {
      position = parseInt(value[value.length - 1].position) + 10
    }
    setValue([
      ...value,
      { modelName: modelKey, key: generateToken(30), position },
    ])
  }

  const removePosition = (models) => {
    if (typeof models[model] !== 'undefined' && typeof models[model].static !== 'undefined') {
      models[model].static = models[model].static.filter(field => field.name !== 'position')
    }
    return models
  }

  const input = (
    <div className="border-2 border-black rounded">
      <div className="flex items-center justify-between p-2 text-white bg-black md:px-4">
        <span>{label}</span>
      </div>
      <div className="p-2 md:p-4">
        <Form
          innerForm={true}
          parentIsSorting={false}
          onChange={(n, v) => updateStored(model, n, v)}
          showErrors={showErrors}
          name={model}
          data={{ models: removePosition(models), stored: removePosition(value), languages }}
        />
      </div>
    </div>
  )

  return translatable ? (
    <LanguageSelector
      language={language}
      onChangeLanguage={v => setLanguage(v)}
    >
      {input}
    </LanguageSelector>
  ) : (
    input
  )
}

export default Model
